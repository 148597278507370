import React from "react";
import { Helmet } from "react-helmet";
import { graphql, withPrefix } from "gatsby";
import Image from "../components/Image";
import Layout from "../components/Layout";
import styles from "../styles/blog-post.module.scss";
import Tag from "../components/Tag";

const Template = ({ data }) => {
  const { markdownRemark: post, site } = data;
  const { siteMetadata } = site;
  return (
    <>
      <Helmet title={post.frontmatter.title}>
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          itemprop="image"
          content={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        />
        <meta property="og:url" content={withPrefix(post.frontmatter.path)} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Non-Essential, But Recommended */}
        <meta property="og:site_name" content={siteMetadata.title} />
        {/* <meta name="twitter:image:alt" content={}/> */}
      </Helmet>
      <article className={styles.blogPost}>
        <header className={styles.blogPostHeader}>
          <h1>{post.frontmatter.title}</h1>
        </header>
        <Image
          className={styles.blogPostFeaturedImage}
          srcInfo={
            <div
              dangerouslySetInnerHTML={{ __html: post.frontmatter.srcInfo }}
            />
          }
          gatsbyImgProps={{
            fluid: post.frontmatter.featuredImage.childImageSharp.fluid,
            alt: "Featured Image",
          }}
        />
        <div className={styles.blogPostBody}>
          <div className={styles.blogPostBodyDetails}>
            <div className={styles.blogPostBodyDetailsInfo}>
              <div className={styles.blogPostBodyDetailsInfoAuthor}>
                {post.frontmatter.author || siteMetadata.author}
              </div>
              <div className={styles.blogPostBodyDetailsInfoDate}>
                {post.frontmatter.date}{" "}
              </div>
              {post.frontmatter.tags && (
                <div className={styles.blogPostBodyDetailsInfoTags}>
                  {post.frontmatter.tags.map((tag) => (
                    <Tag key={tag} tag={tag} />
                  ))}
                </div>
              )}
            </div>
            <div className={styles.blogPostBodyDetailsSeparator} />
          </div>
          <div className={styles.blogPostBodyContent}>
            <div
              className={styles.blogPostBodyContentText}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </div>
        </div>
      </article>
    </>
  );
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        title
        tags
        author
        srcInfo
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;

const TemplateWrapper = (props) => (
  <Layout isReadingPage={true}>
    <Template {...props} />
  </Layout>
);

export default TemplateWrapper;
